import {
  Button,
  Column,
  CompactModal,
  FileInput,
  Icon,
  Input,
  Row,
  Text,
} from "@gradience/ui";
import { useForm } from "@tanstack/react-form";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import { useState } from "react";
import { queryKeys, useApiPost } from "../../../lib/api";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import { useQueryClient } from "@tanstack/react-query";
import readXlsxFile from "read-excel-file";

export const grades = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
] as const;

type Grade = (typeof grades)[number];

function normalizeGrade(input: string | undefined): Grade | undefined {
  if (!input) {
    return undefined;
  }

  // Trim whitespace and convert to lowercase for case-insensitive comparison
  const normalizedInput = input.trim().toLowerCase();

  // Mapping of number words to numbers
  const numbersWordsMap = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
  ];

  const ordinalWordsMap = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
  ];

  if (grades.includes(normalizedInput as any)) {
    return normalizedInput as Grade;
  }

  if (ordinalWordsMap.includes(normalizedInput)) {
    return grades[ordinalWordsMap.indexOf(normalizedInput)];
  }

  if (numbersWordsMap.includes(normalizedInput)) {
    return grades[numbersWordsMap.indexOf(normalizedInput)];
  }

  return undefined;
}

const formDefaults: {
  students: Array<{
    firstName: string | undefined;
    lastName: string | undefined;
    grade: (typeof grades)[number] | undefined;
  }>;
} = {
  students: [
    {
      firstName: undefined,
      lastName: undefined,
      grade: undefined,
    },
  ],
};

const AddStudentsModal = ({ onSubmit }: { onSubmit?: () => unknown }) => {
  const search = useSearch({ from: "/$test-slug/groups/$id/" });
  const params = useParams({
    from: "/$test-slug/groups/$id/",
  });
  const navigate = useNavigate({ from: "/$test-slug/groups/$id" });
  const [entryMethod, setEntryMethod] = useState<"manual" | "upload">("manual");
  const [step, setStep] = useState(0);
  const queryClient = useQueryClient();

  const createStudentsMutation = useApiPost("/student-lists");
  const form = useForm({
    defaultValues: formDefaults,
    onSubmit: async ({ value }) => {
      await createStudentsMutation.mutateAsync({
        groupId: params.id,
        students: value.students.map((s) => ({
          firstName: s.firstName!,
          lastName: s.lastName!,
          grade: s.grade!,
        })),
      });
      form.reset();
      setStep(0);
      await navigate({
        search: (prev) => ({ ...prev, groupModal: undefined }),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys["/students"],
      });
      onSubmit?.();
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <CompactModal
      open={search.groupModal === "add-students"}
      close={() =>
        navigate({
          search: (prev) => ({ ...prev, groupModal: undefined }),
        })
      }
    >
      <Column gap={32}>
        {step === 0 && (
          <>
            <Text textStyle="24px - Bold">Add Students</Text>
            <Column gap={24}>
              <Column gap={16}>
                <Text textStyle="16px - Semibold">
                  How would you like to add students?
                </Text>
                <Row gap={8}>
                  <Button
                    size="huge"
                    text="Add Manually"
                    leadingIcon="plus-01"
                    variant="subdued"
                    style={{ flex: 1 }}
                    onPress={() => {
                      setStep(1);
                      setEntryMethod("manual");
                    }}
                  />
                  <Button
                    size="huge"
                    text="Upload a Spreadsheet"
                    leadingIcon="upload-03"
                    variant="subdued"
                    style={{ flex: 1 }}
                    onPress={() => {
                      setStep(1);
                      setEntryMethod("upload");
                    }}
                  />
                </Row>
              </Column>
              <Column gap={8}>
                <Text textStyle="16px - Semibold">
                  Did you take the ULE last year?
                </Text>
                <Text textStyle="14px - Medium">
                  From the home page, use the dropdown selector in the top right
                  to visit a previous year and duplicate the roster of a group
                  from that previous year into ULE 2025.
                </Text>
              </Column>
            </Column>
          </>
        )}

        <Column
          gap={32}
          style={{
            display: step === 1 && entryMethod === "manual" ? "flex" : "none",
          }}
        >
          <Row gap={4} style={{ alignItems: "center" }}>
            <Icon
              name="arrow-left"
              onClick={() => {
                setStep(0);
              }}
            />
            <Text textStyle="24px - Bold">Add Students</Text>
          </Row>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            <form.Field name="students" mode="array">
              {(field) => {
                return (
                  <div>
                    <Column gap={16}>
                      {field.state.value.map((value, i) => {
                        return (
                          <Row gap={16} key={i}>
                            <Row gap={8}>
                              <form.Field
                                name={`students[${i}].firstName`}
                                validators={{
                                  onBlur: z
                                    .string({
                                      required_error:
                                        "Please enter a first name",
                                    })
                                    .min(1, "Please enter a first name"),
                                }}
                              >
                                {(subField) => {
                                  return (
                                    <Input
                                      inputProps={{
                                        onBlur: subField.handleBlur,
                                      }}
                                      placeholder="First Name"
                                      value={subField.state.value ?? ""}
                                      setValue={subField.handleChange}
                                      errorText={subField.state.meta.errors.join(
                                        ", "
                                      )}
                                    />
                                  );
                                }}
                              </form.Field>
                              <form.Field
                                name={`students[${i}].lastName`}
                                validators={{
                                  onBlur: z
                                    .string({
                                      required_error:
                                        "Please enter a last name",
                                    })
                                    .min(1, "Please enter a last name"),
                                }}
                              >
                                {(subField) => {
                                  return (
                                    <Input
                                      placeholder="Last Name"
                                      value={subField.state.value ?? ""}
                                      setValue={subField.handleChange}
                                      inputProps={{
                                        onBlur: subField.handleBlur,
                                      }}
                                      errorText={subField.state.meta.errors.join(
                                        ", "
                                      )}
                                    />
                                  );
                                }}
                              </form.Field>
                              <form.Field
                                name={`students[${i}].grade`}
                                validators={{
                                  onSubmit: z
                                    .string({
                                      required_error: "Please select a grade",
                                    })
                                    .min(1, "Please select a grade"),
                                }}
                              >
                                {(subField) => {
                                  return (
                                    <Input
                                      type="select"
                                      placeholder="Last Name"
                                      style={{
                                        width: 108,
                                      }}
                                      key={i}
                                      value={subField.state.value}
                                      setValue={(v) =>
                                        subField.handleChange(
                                          v as (typeof grades)[number]
                                        )
                                      }
                                      errorText={subField.state.meta.errors.join(
                                        ", "
                                      )}
                                      options={[
                                        { value: "", label: "" },
                                        ...grades.map((grade) => ({
                                          value: grade,
                                          label: grade,
                                        })),
                                      ]}
                                    />
                                  );
                                }}
                              </form.Field>
                            </Row>

                            <Icon
                              name="trash-04"
                              onClick={() => {
                                field.removeValue(i);
                              }}
                            />
                          </Row>
                        );
                      })}
                      <Button
                        type="button"
                        onPress={() =>
                          field.pushValue({
                            firstName: undefined,
                            lastName: undefined,
                            grade: undefined,
                          })
                        }
                        variant="subdued"
                        leadingIcon="plus-01"
                        size="large"
                        text="Add Student"
                      />
                    </Column>
                  </div>
                );
              }}
            </form.Field>
            <Row gap={8}>
              <Button
                text="Add"
                variant="primary"
                type="submit"
                loading={createStudentsMutation.isPending}
              />
              <Button
                text="Cancel"
                linkProps={{
                  search: (prev) => ({ ...prev, groupModal: undefined }),
                }}
              />
            </Row>
          </form>
        </Column>

        {step === 1 && entryMethod === "upload" && (
          <>
            <Row gap={4} style={{ alignItems: "center" }}>
              <Icon
                name="arrow-left"
                onClick={() => {
                  setStep(0);
                }}
              />
              <Text textStyle="24px - Bold">Add Students</Text>
            </Row>
            <FileInput
              onChange={async (file) => {
                if (!file) {
                  return;
                }

                const MAX_ROWS = 25;

                let rows: string[][] = [];
                if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
                  rows = (await readXlsxFile(file))
                    .slice(0, MAX_ROWS)
                    .map((row) => row.map(String));
                } else if (file.name.endsWith(".csv")) {
                  rows = (await file.text())
                    .split("\n")
                    .slice(0, MAX_ROWS)
                    .map((row) => row.split(","));
                }
                for (const row of rows) {
                  // Skip header row, doesn't need to be too intelligent, low
                  // stakes since they can just delete the row in the next step
                  if (
                    row[0]?.toString().trim().toLowerCase() === "first name" &&
                    row[1]?.toString().trim().toLowerCase() === "last name" &&
                    row[2]?.toString().trim().toLowerCase() === "grade"
                  ) {
                    continue;
                  }
                  form.pushFieldValue("students", {
                    firstName: row[0]?.toString() ?? "",
                    lastName: row[1]?.toString() ?? "",
                    grade: normalizeGrade(row[2]?.toString()),
                  });
                }

                setEntryMethod("manual");
              }}
              accept="spreadsheet"
            />
          </>
        )}
      </Column>
    </CompactModal>
  );
};

export default AddStudentsModal;
